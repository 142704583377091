/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.red {
    background-color: red;
}

.blue {
    background-color: blue;
}

.mt-1 {
    margin-top: 10px;
}

.mt-2 {
    margin-top: 20px;
}

.mt-3 {
    margin-top: 30px;
}

.mt-4 {
    margin-top: 40px;
}

.mt-5 {
    margin-top: 50px;
}

.ml-1 {
    margin-left: 10px;
}

.ml-2 {
    margin-left: 20px;
}

.ml-3 {
    margin-left: 30px;
}

.ml-4 {
    margin-left: 40px;
}

.ml-5 {
    margin-left: 50px;
}

.m-1 {
    margin: 10px;
}
.m-2 {
    margin: 20px;
}
.m-3 {
    margin: 30px;
}
.m-4 {
    margin: 40px;
}
.m-5 {
    margin: 50px;
}

.green-background {
    background: #68b37d;
}

.red-background {
    background: #d42032;
}

.white-smoke-background {
    background: whitesmoke;
}

.text-black {
    color: black;
}

.text-red {
    color: #d42032;
}

.text-green {
    color: #68b37d;
}

.mdc-notched-outline__notch
{
    border-right: none;
}